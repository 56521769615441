import React, { useEffect, useState } from 'react';
import LessonButton from '../components/LessonButton';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Lessons = ({ isConnected, user, getUser }) => {

  const [organisedLessons, setOrganisedLessons] = useState({});

  const organiseLessons = (lessons) => {
    const categories = {};

    lessons?.forEach((lesson) => {
      const { saison } = lesson;
      if (!categories[saison]) {
        categories[saison] = [];
      }
      categories[saison].push(lesson);
    });

    setOrganisedLessons(categories);
  };

  useEffect(() => {
    isConnected();

    if(!document.body.classList.contains("fingers"))
      document.body.classList.add("fingers");

    const fetchData = async () => {
      await getUser(JSON.parse(localStorage.getItem("user"))[0].telephone)
        .then((response) => {
          organiseLessons(JSON.parse(localStorage.getItem("user"))[0].lessons);
        })

    }

    fetchData();
  }, [])
  return (
    <>
      <Header />
      <main className="lessons-component">
        <h1>Ma collection de fiches</h1>
        {Object.entries(organisedLessons).map(([categoryNumber, lessons]) => (
          <div key={categoryNumber}>
            <h2>Saison {categoryNumber} :</h2>
            <ul>
              {lessons.map((lesson, index) => (
                <LessonButton key={index} lesson={lesson} />
              ))}
            </ul>
          </div>
        ))}
      </main>
      <Footer visible={true} active={"lessons"} />
    </>
  );
};

export default Lessons;