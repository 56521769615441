import React, { useEffect } from 'react';

const LessonFile = ({ lesson }) => {

  useEffect(() => {
    console.log(lesson);
  }, []);

  return (
    <li className='lesson-button'>
    <a href={`./assets/pdf/technical-sheets/${lesson.filename}-${sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : "fr"}.pdf`} download={true} dangerouslySetInnerHTML={{ __html: `
    <span>
      <strong>Saison ${lesson.saison} - Episode ${lesson.episode} :</strong>
      <p>${lesson.title}</p>
    </span>
    <img src="./assets/images/download-icon.svg" alt="Télécharger la fiche" />
    ` }}></a>
  </li>
  );
};

export default LessonFile;