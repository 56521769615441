import React, { useEffect, useState } from 'react';

const SelectV2Popup = ({closePopup, answerSelection, answers, values, indexSelected, answersDisplay, label}) => {

  const [isOpened, setIsOpened] = useState(false);

  const handleClose = () => {

    document.body.style.overflowY = "auto";
    document.querySelector("html").style.overflowY = "auto";


    setIsOpened(false);

    let timeout = window.setTimeout(() => {
      closePopup();

      return () => {
        clearTimeout(timeout);
      }
    }, 300)

  }

  const handleClick = (ev) => {
    answerSelection(ev.target.dataset.index, ev.target.dataset.value);
    handleClose();
  }

  const handleExit = (ev) => {
    if (ev.target.tagName !== "BUTTON" && ev.target.tagName !== "H3") {
      handleClose();
    }
  }

  useEffect(() => {
    if (!isOpened) {
      let timeout = window.setTimeout(() => {
        setIsOpened(true);
        return () => {
          clearInterval(timeout)
        }
      }, 100)

      document.body.style.overflowY = "hidden";
      document.querySelector("html").style.overflowY = "hidden";
    }
  }, [])

  return (
    <section className={isOpened ? "select-popup visible" : "select-popup"}>
      <article onClick={handleExit}>
        <div className='select-label' dangerouslySetInnerHTML={{ __html: label }}></div>
        <h3>Sélectionne la bonne réponse :</h3>
          <ul>
          {answers.map((answer, index) => 
            <li key={index}>
              <button className={parseInt(indexSelected) > -1 && parseInt(indexSelected) === index ? "select-option selected " + answersDisplay : "select-option " + answersDisplay} data-value={values[index]} data-index={index} dangerouslySetInnerHTML={{ __html: answer }} onClick={handleClick}></button>
            </li>
          )}
          </ul>
      </article>
    </section>
  );
};

export default SelectV2Popup;