import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// Ajout pour forcer la mise à jour si nouvelle version
serviceWorkerRegistration.register({
    onUpdate: registration => {
        if (registration && registration.waiting) {
            // Option 1 : Rechargement immédiat (sans confirmation)
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });

            registration.waiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });

            // Option 2 : avec confirmation utilisateur
            // if (window.confirm("Une nouvelle version est disponible. Recharger maintenant ?")) {
            //     registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            //     registration.waiting.addEventListener('statechange', e => {
            //         if (e.target.state === 'activated') {
            //             window.location.reload();
            //         }
            //     });
            // }
        }
    }
});
