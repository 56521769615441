import React, { useState } from 'react';
import { useEffect } from 'react';

const GameIntro = ({ changeState, datas, loaderVisible }) => {

  const [state, setState] = useState(0);
  const [intro, setIntro] = useState([]);

  const handleNext = () => {
    setState(state + 1);
  }

  const handlePreview = () => {
    setState(state - 1);
  }

  const startDefi = () => {
    loaderVisible();
    changeState();
  }

  const tmpArray = datas;

  useEffect(() => {
    if (intro.length === 0) {
      setIntro(tmpArray);
    }
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'auto' // Cette ligne est facultative car 'auto' est le comportement par défaut
    });
  }, [state])

  return (
    <section className="game-intro">
      <img src="./assets/images/formateurs.png" alt="Romain et Stéphane les formateurs de Connivence" />
      <div className="dialog-box" dangerouslySetInnerHTML={{ __html: intro[state] }}></div>
      <div className='signatures' style={state === intro.length - 1 ? { display: "flex" } : { display: "none" }}>
        <img src="./assets/images/signature_romain.png" alt="Signature de Romain" />
        <img src="./assets/images/signature_stephane.png" alt="Signature de Stéphane" />
      </div>
      <div className="intro-actions">
        {
          state === intro.length - 1 ?
            <button className='play-button' onClick={() => startDefi()}>C'est parti !</button>
            :
            <>
              <button className={state === 0 ? "previous disabled" : "previous"} onClick={() => state === 0 ? "" : handlePreview()}>
                <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.48077 0L0 1.75L7.82692 10.5L0 19.25L1.48077 21L11 10.5L1.48077 0Z" fill="white" />
                </svg>
              </button>
              <button className={"next"} onClick={() => state === intro.length - 1 ? "" : handleNext()}>
                <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.48077 0L0 1.75L7.82692 10.5L0 19.25L1.48077 21L11 10.5L1.48077 0Z" fill="white" />
                </svg>
              </button>
            </>
        }
      </div>
    </section>
  );
};

export default GameIntro;