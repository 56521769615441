import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Medal from './Medal';
import Emoji from './Emoji';

const GameRecap = ({ points, unlocked, changeState, user, medals, nbFails, defi, getUser }) => {


  const [scores, setScores] = useState();
  const [bon, setBon] = useState(0);
  const [initd, setInitd] = useState(false);

  const getFailsBonus = () => {
    if (nbFails === 0) {
      return 15;
    }
    else if (nbFails < 5) {
      return nbFails * 2;
    }
    else {
      return 10;
    }
  }

  const getSpeedBonus = () => {
    return bon;
  }

  const getSpeed = () => {
    if (bon === 20) {
      return "premier"
    }
    else if (bon === 16) {
      return "deuxième"
    }
    else if (bon === 10) {
      return "troisième"
    }
    else {
      return "dernier";
    }
  }

  const resetCheckpoint = () => {
    axios.post('https://connivence-salon.net/api/e-learning/game/post/checkpoint.php', new URLSearchParams({
      user: user.id, checkpoint: 0, points: 0
    })).then(response => {
      console.log("Progression sauvegardée");
    }).catch(error => {
      // Gérer l'erreur ici, par exemple en affichant un message d'erreur
      console.error('Erreur lors de l\'envoi de la requête:', error);
      // Peut-être définir un état pour afficher l'erreur dans le popup
    });
  }

  useEffect(() => {

    console.log(points);

    if (initd === false) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto' // Cette ligne est facultative car 'auto' est le comportement par défaut
      });

      document.body.className = "recap-bg";
    }

    resetCheckpoint();

    axios.get('https://connivence-salon.net/api/e-learning/content/achievements.php', {
      params: {
        user: user?.id
      }
    })
      .then(response => {
        if (response.data && response.data.success) {
          setScores(response.data.content);
          axios.get('https://connivence-salon.net/api/e-learning/game/get/success.php', {
            params: {
              defi,
              user: user?.id
            }
          })
            .then(response => {
              if (response.data && response.data.rank) {
                const rank = parseInt(response.data.rank);
                let bn = 0;
                if (rank === 1) {
                  bn = 20;
                  setBon(20);

                }
                else if (rank === 2) {
                  bn = 16;
                  setBon(16);
                }
                else if (rank === 3) {
                  bn = 10;
                  setBon(10);
                }
                else {
                  bn = 0;
                  setBon(0);
                }

                setInitd(true);

                if(initd === false) {
                  if (JSON.parse(localStorage.getItem("user"))[0] && JSON.parse(localStorage.getItem("user"))[0].telephone && JSON.parse(localStorage.getItem("user"))[0].lng) {
                    getUser(JSON.parse(localStorage.getItem("user"))[0]?.telephone, JSON.parse(localStorage.getItem("user"))[0]?.lng);
                  }
                }

                /*if (initd === false) {
                  axios.post('https://connivence-salon.net/api/e-learning/game/post/endgame.php', new URLSearchParams({
                    user: user.id, defi, points: parseInt(points) + getFailsBonus() + bn
                  })).then(response => {
                    
                    if (JSON.parse(localStorage.getItem("user"))[0] && JSON.parse(localStorage.getItem("user"))[0].telephone && JSON.parse(localStorage.getItem("user"))[0].lng) {
                      getUser(JSON.parse(localStorage.getItem("user"))[0]?.telephone, JSON.parse(localStorage.getItem("user"))[0]?.lng);
                    }

                  }).catch(error => {
                    // Gérer l'erreur ici, par exemple en affichant un message d'erreur
                    console.error('Erreur lors de l\'envoi de la requête:', error);
                    // Peut-être définir un état pour afficher l'erreur dans le popup
                  });
                }*/
              }
              // Traiter la réponse ici
            })
            .catch(error => {
              console.error('Erreur:', error);
              // Gérer l'erreur ici
            });
        }
        // Traiter la réponse ici
      })
      .catch(error => {
        console.error('Erreur:', error);
        // Gérer l'erreur ici
      });
  }, [user])
  return (
    <section className="game-recap" >
      <h3>Récapitulatif</h3>
      <article>
        <Emoji src="congrats" />
        <h4>Vous avez récolté <strong className='colored'>{parseInt(points) + getFailsBonus() + getSpeedBonus()} points</strong> en réussissant ce défi 👏</h4>
        <ul>
          <li><p>Total du défi</p><strong>{points} points</strong></li>
          {
            nbFails === 0 ?
              <li><p>Bonus : sans faute</p><strong>+15</strong></li>
              :
              <li><p>Bonus : persévérance</p><strong>+{getFailsBonus()}</strong></li>
          }
          {
            bon > 0 ?
              <li><p>Bonus : {getSpeed()} plus rapide</p><strong>+{getSpeedBonus()}</strong></li>
              :
              ""
          }
        </ul>
      </article>
      <article>
        <h4>Vous avez aussi débloqué {unlocked} {parseInt(unlocked) > 1 ? "nouvelles fiches techniques" : "nouvelle fiche technique"} ! </h4>
        <p>Vous avez désormais un total de <strong>2 fiches dans votre collection.</strong></p>
      </article>
      <article>
        <h4>Progression de vos récompenses</h4>
        <ul>
          {
            medals?.map((medal, index) =>
              <Medal id={index} key={index} data={medal} progress={scores ? scores[index] : 0} />)
          }
        </ul>

      </article>
      <button onClick={() => {
        changeState();
        resetCheckpoint();
        
        }}>
        Suivant
      </button>
    </section>
  );
};

export default GameRecap;