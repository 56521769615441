import React, { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PayoutPopup from '../components/PayoutPopup';
import axios from 'axios';
import { useEffect } from 'react';

const Pot = ({ user, setUser }) => {
  const [opened, setOpened] = useState(null);
  const [money, setMoney] = useState();
  const [history, setHistory] = useState();
  const [text, setText] = useState("Texte à copier");

  const closePopup = () => {
    setOpened(null);
  }

  const copyToClipboard = async (ev) => {
    try {
      await navigator.clipboard.writeText(text);

      ev.target.textContent = "Lien copié !";
      ev.target.blur();

      let timeout = setTimeout(() => {
        ev.target.textContent = "Parrainer un utilisateur";
      }, 1000)

      return () => {
        clearTimeout(timeout);
      };

    } catch (err) {
      console.error("Échec de la copie : ", err);
    }
  };

  useEffect(() => {
    if(!document.body.classList.contains("fingers"))
      document.body.classList.add("fingers");
    
    if (!money) {
      axios.get('https://connivence-salon.net/api/e-learning/paypal/balance.php', {
        params: {
          user: user?.id
        }
      })
        .then(response => {
          setMoney(response.data.content);
          // Traiter la réponse ici
        })
        .catch(error => {
          console.error('Erreur:', error);
          // Gérer l'erreur ici
        });
    }
    axios.get('https://connivence-salon.net/api/e-learning/paypal/history.php', {
      params: {
        user: user?.id
      }
    })
      .then(response => {
        setHistory(response.data.content);
        // Traiter la réponse ici
      })
      .catch(error => {
        console.error('Erreur:', error);
        // Gérer l'erreur ici
      });
  }, [user, money])

  useEffect(() => {
    setText("https://connivence-formation.net/e-learning/inscription.php?parrain=" + user?.sponsor_link);
  }, [user])

  return (
    <>
      <Header />
      <main className="pot-component">
        <h1>Ma cagnotte</h1>
        <div className='pot-content'>
          <h2>{money?.replace(".", ",")} €</h2>
          <p>Dans ma cagnotte</p>

        </div>
        <div className='actions'>
          <button className='payout' onClick={() => setOpened(1)}>
            Récupérer ma cagnotte
          </button>
          <button className='sponsor' onClick={copyToClipboard}>
            Parrainer un utilisateur
          </button>
        </div>
        <h3>Mes 10 dernières transactions</h3>
        <ul className='history'>
          {
            history?.map((transfer, index) =>
              <li className={transfer.name === "Transfert" ? "sponsor-minus" : "sponsor-plus"} key={index}>
                <p>{transfer.name}</p>
                <p>{transfer.name === "Transfert" ? "-" : "+"} {transfer.amount} €</p>
              </li>
            )
          }
        </ul>

        {
          opened !== null ?

            <PayoutPopup opened={opened} closePopup={closePopup} user={user} setUser={setUser} money={money} setMoney={setMoney} />


            :
            ""
        }

        { /*
        <Link to={"/profile"} className="button-like">
          <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M96.8072 2.2679C96.1125 1.57178 95.2873 1.0195 94.3789 0.642687C93.4705 0.26587 92.4967 0.0719093 91.5133 0.0719093C90.5298 0.0719093 89.556 0.26587 88.6476 0.642687C87.7392 1.0195 86.9141 1.57178 86.2194 2.2679L49.5 38.9122L12.7806 2.1928C12.0854 1.4976 11.2601 0.946132 10.3517 0.56989C9.44342 0.193647 8.46988 7.32517e-09 7.48671 0C6.50354 -7.32518e-09 5.53 0.193647 4.62167 0.56989C3.71334 0.946132 2.88801 1.4976 2.1928 2.1928C1.4976 2.88801 0.946132 3.71334 0.56989 4.62167C0.193647 5.53 -7.32517e-09 6.50354 0 7.48671C7.32518e-09 8.46988 0.193647 9.44342 0.56989 10.3517C0.946132 11.2601 1.4976 12.0854 2.1928 12.7806L38.9122 49.5L2.1928 86.2194C1.4976 86.9146 0.946132 87.7399 0.56989 88.6483C0.193647 89.5566 0 90.5301 0 91.5133C0 92.4965 0.193647 93.47 0.56989 94.3783C0.946132 95.2867 1.4976 96.112 2.1928 96.8072C2.88801 97.5024 3.71334 98.0539 4.62167 98.4301C5.53 98.8064 6.50354 99 7.48671 99C8.46988 99 9.44342 98.8064 10.3517 98.4301C11.2601 98.0539 12.0854 97.5024 12.7806 96.8072L49.5 60.0878L86.2194 96.8072C86.9146 97.5024 87.7399 98.0539 88.6483 98.4301C89.5566 98.8064 90.5301 99 91.5133 99C92.4965 99 93.47 98.8064 94.3783 98.4301C95.2867 98.0539 96.112 97.5024 96.8072 96.8072C97.5024 96.112 98.0539 95.2867 98.4301 94.3783C98.8064 93.47 99 92.4965 99 91.5133C99 90.5301 98.8064 89.5566 98.4301 88.6483C98.0539 87.7399 97.5024 86.9146 96.8072 86.2194L60.0878 49.5L96.8072 12.7806C99.6606 9.92716 99.6606 5.12135 96.8072 2.2679Z" fill="white" />
          </svg>
          Fermer
        </Link>
        */ }
      </main>
      <Footer visible={true} active={"profile"} />
    </>
  );
};

export default Pot;