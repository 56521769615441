import React, { useEffect, useRef, useState } from 'react';

const GreenScreen = () => {

  const [animating, setAnimating] = useState(false);

  const element = useRef(null);

  useEffect(() => {
    if (!animating) {
      setAnimating(true);
      
      setTimeout(() => {
        if (element.current) element.current.style.opacity = "0.9";
      }, 100);
      
      setTimeout(() => {
        if (element.current) element.current.style.transition = "opacity .5s";
      }, 200);
      
      setTimeout(() => {
        if (element.current) element.current.style.opacity = "0";
      }, 300);
      
      setTimeout(() => {
        if (element.current) element.current.style.transition = "opacity .1s";
        setAnimating(true);
      }, 800);
    }
  }, [element]);

  return (
    <div className='red-screen correct' ref={element}>
      
    </div>
  );
};

export default GreenScreen;