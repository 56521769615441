import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Footer from '../components/Footer';
import GameIntro from '../components/GameIntro';
import GameLesson from '../components/GameLesson';
import GameRecap from '../components/GameRecap';
import GameSlides from '../components/GameSlides';
import GameTeaser from '../components/GameTeaser';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';

const Game = ({ user, isConnected, medals, getUser, setUser }) => {

  //const id = parseInt(JSON.parse(localStorage.getItem("user"))[0]?.defi);

  const [id, setId] = useState(user ? parseInt(user.defi) : 2);

  const [state, setState] = useState(0);
  const [datas, setDatas] = useState();
  const [points, setPoints] = useState(0);
  const [loaderState, setLoaderState] = useState(0);
  const [slideName, setSlideName] = useState("test");
  const [nbFails, setNbFails] = useState(0);
  const [verification, setVerification] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();


  const changeState = () => {
    if (state < 4) {
      setState(state + 1);
        window.scrollTo({
          top: 0,
          behavior: "smooth" // Animation fluide
      });
    }

  }

  const loaderVisible = () => {
    if(loader === false){
      setLoader(true);
    }
  }

  const closeLoader = () => {
    setLoader(false);
  }

  const setHeaderTitle = () => {
    if (state !== 1) {
      if (state === 2) {
        return null;
      }
      else {
        return "";
      }
    }
    else {
      let first_part = datas.chapter.split(" : ")[0];
      let fp_array = first_part.split("-");
      //console.log([fp_array[0], fp_array[1], slideName]);
      //return first_part + "<span class='red'>" + slideName + "</span>"
      return [fp_array[0].trim(), fp_array[1].trim(), slideName]
    }
  }

  useEffect(() => {


    console.log(datas);

    if (!user || !user.defi) {
      console.warn("L'objet user n'est pas encore disponible.");
      return;
    }

    console.log(user)

    setId(parseInt(user.defi));

    if(!verification && loaderState === 0){
      setVerification(true);
      axios.get('https://connivence-salon.net/api/e-learning/game/get/checkdefi.php', {
        params: {
          user: JSON.parse(localStorage.getItem("user"))[0]?.id
        }
      })
        .then(response => {
          if (response.data) {
            if (parseInt(response.data.success) !== id) {
              setId(parseInt(response.data.success));
              if(user){
                setUser(prevUser => {
                    const updatedUser = { ...prevUser, defi: response.data.success };
                    const storageUpdated = [updatedUser]
                    localStorage.setItem("user", JSON.stringify(storageUpdated)); // Mettre à jour localStorage immédiatement
                    return updatedUser;
                });
              }
              //navigate("/game");
            }

          }
          // Traiter la réponse ici
        })
        .catch(error => {
          console.error('Erreur:', error);
          // Gérer l'erreur ici
        });

    }



    if (document.body.classList.contains("fingers"))
      document.body.classList.remove("fingers");
    if (loaderState === 0) {
      axios.get('https://connivence-salon.net/api/e-learning/game/get/availability.php', {
        params: {
          user: JSON.parse(localStorage.getItem("user"))[0]?.id
        }
      })
        .then(response => {
          if (response.data) {
            if (parseInt(response.data.success) !== 0) {
              //REMETTRE
              navigate("/home");
            }

          }
          // Traiter la réponse ici
        })
        .catch(error => {
          console.error('Erreur:', error);
          // Gérer l'erreur ici
        });
      let timeout = [];
      timeout[0] = window.setTimeout(() => {
        setLoaderState(1);
        console.log(loaderState);

        return () => {
          clearTimeout(timeout[0]);
        }
      }, 500)
      timeout[1] = window.setTimeout(() => {
        setLoaderState(2);
        console.log(loaderState);

        return () => {
          clearTimeout(timeout[1]);
        }
      }, 3000)
      timeout[2] = window.setTimeout(() => {
        setLoaderState(3);
        console.log(loaderState);

        return () => {
          clearTimeout(timeout[2]);
        }
      }, 3500)
      timeout[3] = window.setTimeout(() => {
        setLoaderState(4);
        console.log(loaderState);

        return () => {
          clearTimeout(timeout[3]);
        }
      }, 6200)
    }
    isConnected();
    axios.post('https://connivence-salon.net/api/e-learning/content/defi.php', new URLSearchParams({
      id: parseInt(user.defi),
      lang: sessionStorage.getItem("lang")
    }))
      .then(function (response) {
        console.log(response.data.content)
        setDatas(response.data.content[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [user])

  return (
    <>
      <Header title={setHeaderTitle()} state={state} minified={state === 1 || state === 2 ? true : false} color={state === 2 ? "black" : undefined} />
      <main className="game-component">

        <section id='loader' className={"state-" + loaderState}>
          <div id='slide-1'>
            <img src="./assets/images/logo.png" />
          </div>
          <div id='slide-2'>
            <h2 dangerouslySetInnerHTML={{ __html: datas?.chapter }}>
            </h2>
            <img src={datas?.cover} alt="" />
          </div>
          <img className='bg-pattern' src="./assets/images/logo_pattern.png" />
        </section>

        {
          datas ?
            <>
              {
                state === 0 ?
                  <GameIntro changeState={changeState} loaderVisible={loaderVisible} datas={JSON.parse(datas?.intro)} />
                  :
                  ""
              }
              {
                state === 1 ?
                  <GameSlides slides={datas?.slides} setPoints={setPoints} points={points} changeGlobalState={changeState} setSlideName={setSlideName} medals={medals} user={user} defi={id} nbFails={nbFails} setNbFails={setNbFails} loaderVisible={loaderVisible} />
                  :
                  ""
              }
              {
                state === 2 ?
                  <GameLesson files={datas?.files} changeState={changeState} user={user} defi={id} points={points} unlocked={datas?.unlocked} getUser={getUser} nbFails={nbFails} />
                  :
                  ""
              }
              {
                state === 3 ?
                  <GameRecap points={points} unlocked={datas?.unlocked} changeState={changeState} user={user} medals={medals} nbFails={nbFails} defi={id} getUser={getUser} />
                  :
                  ""
              }
              {
                state >= 4 ?
                  <GameTeaser content={datas?.teaser} user={user} />
                  :
                  ""
              }
            </>
            :
            ""
        }
        {loader ? <Loading closeLoader={closeLoader}  /> : ""}
      </main>
      <Footer visible={false} active={"home"} />
    </>
  );
};

export default Game;