import { clear } from '@testing-library/user-event/dist/clear';
import React, { useEffect, useState } from 'react';

const Loading = ({closeLoader}) => {

  const [loaderState, setLoaderState] = useState(0)

  useEffect(() => {
    let timeout = setTimeout(() => { 
      setLoaderState(1);
    }
    , 300);

    let timeout2 = setTimeout(() => { 
      closeLoader();
    }
    , 500);

    return () => {
      clearTimeout(timeout);
    }
  } , []);

  return (
    <div className={loaderState === 0 ? "loading-container" : "loading-container fade-out"}>
      <span className="loader"></span>
    </div>
  );
};

export default Loading;