import React, { useEffect, useState } from 'react';
import Paragraph from './Paragraph';

const SlideText = ({ slide, changeState, right, user, newCheckpoint }) => {
  const [paragraphs, setParagraphs] = useState([]);
  const [state, setState] = useState(0);
  const [parent, setParent] = useState(0);
  const path = "https://connivence-salon.net/api/e-learning/assets/images/";

  const changeLocalState = (value) => {
    setState(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Cette ligne est facultative car 'auto' est le comportement par défaut
    });
  }

  const isVisible = (index) => {
    if(state === index){
      return true;
    }
    else{
      if(slide?.num !== parent){
        setParent(slide.num);
        setState(0);
        return true;
      }
      else{
        return false;
      }
    }
  }

  useEffect(() => {
    
    setParagraphs(slide.paragraphs);
    console.log(slide.correction)

    if (slide?.lesson === "1" && !document.body.classList.contains("lessons-bg")) {
      document.body.classList.add("lessons-bg");
    }

    console.log(slide?.num);
  }, [slide])
  return (
    <>
      {slide?.lesson === "1" ?
        <article className={"tplt-" + slide.template + " lesson"}>

          {
            paragraphs?.map((par, index) =>
              <Paragraph key={index} par={par} pos={index + 1} maxPos={paragraphs.length} visible={isVisible(index)} changeLocalState={changeLocalState} changeState={changeState} user={user} newCheckpoint={newCheckpoint} slide={slide} />
            )
          }
        </article>
        :
        ""
      }
      {slide?.lesson === "0" && !(slide.correction === "1" && right === false) ?
        <article className={"tplt-" + slide.template} dangerouslySetInnerHTML={{ __html: slide.text_content }}>
        </article>
        :
        ""
      }
      {
        slide?.correction === "1" && right === false ?
          <article className='tplt-inline-text'>
            <div>
              <h3>Oups ! Ce n'est pas la bonne réponse...</h3>
              <p className='line-break'>... mais la réussite est l'aboutissement d'une succession d'échecs !</p>
              <strong>Pas de panique, je vais y arriver... Il faut persévérer !</strong>
            </div>
          </article>
          :
          ""
      }
    </>
  );
};

export default SlideText;