import React, { useRef, useState } from 'react';

const SelectsContainer = ({ changeUserAnswers, label, answers, selectIndex, cardsDescs }) => {

  const selectRef = useRef();

  const [value, setValue] = useState(-1);

  const handlerChange = () => {
    if (selectRef.current)
      setValue(parseInt(selectRef.current.value));
    changeUserAnswers(selectIndex, selectRef.current.value)
  }


  const getFakeLabel = () => {
    if(cardsDescs !== null){
      return label + cardsDescs;
    }
    else{
      return label;
    }
  }

  
  return (
    <li>
      <div className={cardsDescs !== null ? 'fake-label with-card' : 'fake-label'} dangerouslySetInnerHTML={{ __html: getFakeLabel() }}></div>
      <select ref={selectRef} onChange={handlerChange} >
        {value === -1 ?
          <option value="-1">???</option>
          :
          ""
        }
        {answers.map((answer, index) =>
          <option value={index} key={index}>{answer}</option>
        )
        }
      </select>
    </li>
  );
};

export default SelectsContainer;