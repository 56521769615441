import React, { useEffect, useState } from 'react';
import ChipsRadios from './ChipsRadio';

const SlidePicturesChips = ({slide, setPoints, points, changeState, setRight}) => {
  const [userAnswers, setUserAnswers] = useState();

  const correction = JSON.parse(slide.round_correction);

  const handleChange = (value, index) => {
    setUserAnswers(prevAnswers => {
      const updatedAnswers = [...prevAnswers]; // Clone the previous array
      updatedAnswers[index] = parseInt(value); // Update the specific index
      return updatedAnswers; // Return the updated array
    });
  }

  const checkAnswers = () => {
    let ready = true;
    userAnswers.forEach((answer) => {
      if (parseInt(answer) < 0) {
        console.log("test")
        ready = false;
      }
    });

    return ready;
  }

  const handleValidate = () => {
    if (userAnswers.length >= JSON.parse(slide.round_correction).length && checkAnswers()) {

      if (JSON.stringify(correction) === JSON.stringify(userAnswers)) {
        setPoints(points + 20);
        console.log("bon");
        changeState();
      }
      else {
        setRight(false);
        changeState();
      }
    }
  }

  useEffect(() => {

    let newAnswers = [];

    for(let i = 0; i < JSON.parse(slide?.round_labels).length; i++){
      newAnswers.push(-1);
    }

    setUserAnswers(newAnswers);
  }, [slide])

  return (
    <article className={"tplt-" + slide.template}>
      <div className="question" dangerouslySetInnerHTML={{ __html: slide.question }}>
      </div>
      {JSON.parse(slide?.round_labels).map((label, index) => 
          <div className={"answer-container part-" + String(index + 1)} key={index}>
            <div className='answer-picture' dangerouslySetInnerHTML={{ __html: label }}>
            </div>
            <ChipsRadios i={index} type={"images"} colors={JSON.parse(slide?.round_answers)} handleChange={handleChange} />
          </div>
      )}

      <button onClick={handleValidate}>
        Valider
      </button>

    </article>
  );
};

export default SlidePicturesChips;