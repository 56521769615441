import React, { useState } from 'react';
import SelectV2Popup from './SelectV2Popup';

const SelectsContainerV2 = ({ changeUserAnswers, label, answers, selectIndex, values, answersDisplay, cardsDescs }) => {

  const [popupOpened, setPopupOpened] = useState(false);
  const [indexSelected, setIndexSelected] = useState(-1);
  const [valueSelected, setValueSelected] = useState("");

  const closePopup = () => {
    setPopupOpened(false);
  }

  const handleOpen = () => {
    setPopupOpened(true);
  }

  const answerSelection = (index, value) => {
    changeUserAnswers(selectIndex, index);
    setValueSelected(value);
    setIndexSelected(index);
  }

  const getFakeLabel = () => {
    if(cardsDescs !== null){
      return label + cardsDescs;
    }
    else{
      return label;
    }
  }

  return (
    <li>
    <div className={cardsDescs !== null ? 'fake-label black-card' : 'fake-label'} dangerouslySetInnerHTML={{ __html: getFakeLabel() }}></div>
      <button className={indexSelected === -1 ? 'select-button missing' : 'select-button not-missing'} onClick={handleOpen}>
        {indexSelected === -1 ? "???" : valueSelected}
      </button>
      { popupOpened ?
      <SelectV2Popup closePopup={closePopup} answerSelection={answerSelection} answers={answers} values={values} indexSelected={indexSelected} answersDisplay={answersDisplay} label={label}  />
      :
      ""
      }
    </li>
    
  );
};

export default SelectsContainerV2;