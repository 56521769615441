import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GameFooter = ({ slide }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkScroll = () => {
      const screenHeight = window.innerHeight;
      const pageHeight = document.documentElement.scrollHeight;
      setShowScrollButton(pageHeight > screenHeight + 100);
    };

    // Délai pour s'assurer que tout est bien chargé
    const timeout = setTimeout(() => {
      checkScroll();
    }, 100); // Petit délai de 100ms pour éviter les erreurs

    window.addEventListener('resize', checkScroll);
    
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', checkScroll);
    };
  }, [slide]);

  const scrollDown = () => {
    window.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
  };

  const closePopup = (ev) => {
    if(ev.target.classList.contains("pu_container")){
      setPopupOpened(false);
    }
  }

  useEffect(() => {
    // Désactiver le scroll si popupOpened est vrai
    if (popupOpened) {
      document.querySelector("html").style.overflowY = "hidden";
    } else {
      document.querySelector("html").style.overflowY = "auto";
    }
  
    // Nettoyage : rétablir l'overflow quand le composant est démonté
    return () => {
      document.querySelector("html").style.overflowY = "auto";
    };
  }, [popupOpened]);
  

  return (
    <div className='footer-like' onClick={closePopup}>
      <button className='leave-episode' onClick={() => setPopupOpened(true)}>
        <img src="/assets/images/leave.svg" alt="Quitter l'épisode et le reprendre plus tard" />
      </button>
      {showScrollButton && (
        <button onClick={scrollDown} style={{display:"none"}}>
          <img src="/assets/images/to-bottom.svg" alt="Descendre" />
        </button>
      )}
      <div className={popupOpened ? 'pu_container visible' : 'pu_container'}>
        <div className='pu-content'>
          <h3>Veux-tu vraiment faire pause ?</h3>
          <p>Ne t'inquiète pas, ta progression est sauvegardée automatiquement !</p>
          <div className='actions'>
            <button onClick={() => navigate("/home")}>Oui</button>
            <button onClick={() => setPopupOpened(false)}>Non</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameFooter;