import React, { useRef, useState } from 'react';
import SelectV2Popup from './SelectV2Popup';

const SlideSumSelects = ({slide, setPoints, points, changeState, setRight}) => {

  const [popupOpened, setPopupOpened] = useState(false);

  const [indexSelected, setIndexSelected] = useState(-1);
  const [valueSelected, setValueSelected] = useState("");

  const submitBtn = useRef(null);

  const handleValidate = () => {
    if (parseInt(indexSelected) > -1) {

      if (parseInt(slide?.good_answer) === parseInt(indexSelected)) {
        setPoints(points + 10);
        console.log("bon");
        changeState();
      }
      else {
        setRight(false);
        changeState();
      }
    }
  }

  const closePopup = () => {
    setPopupOpened(false);
  }

  const handleOpen = () => {
    setPopupOpened(true);
  }

  const answerSelection = (index, value) => {
    setValueSelected(value);
    setIndexSelected(index);
    const timeoutId = setTimeout(() => {
      submitBtn.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    return () => clearTimeout(timeoutId);
  }

  return (
    <article className={"tplt-" + slide.template}>
      <div className="question" dangerouslySetInnerHTML={{ __html: slide.question }}>
      </div>

      <div className='sum-container'>
        <div className='sum-content'>
          <span dangerouslySetInnerHTML={{ __html: JSON.parse(slide?.round_labels)[0] }}></span>
          <p className='operator'><strong>+</strong></p>
          <button onClick={handleOpen} className={indexSelected === -1 ? 'answer-sample missing' : 'answer-sample not-missing'}>{indexSelected === -1 ? "???" : valueSelected}</button>
        </div>
        <p className='operator'><strong>=</strong></p>
        <div className='sum-result' dangerouslySetInnerHTML={{ __html: JSON.parse(slide?.round_labels)[1] }}></div>
      </div>

      { popupOpened ?
      <SelectV2Popup closePopup={closePopup} answerSelection={answerSelection} answers={JSON.parse(slide?.round_answers)} values={JSON.parse(slide?.answers)} indexSelected={indexSelected} answersDisplay={slide?.answers_display}  />
      :
      ""
      }

      <button className='submit-button' onClick={handleValidate} ref={submitBtn}>
        Valider
      </button>

    </article>
  );
};

export default SlideSumSelects;