import React, { useEffect, useState } from 'react';
import Emoji from './Emoji';
import LessonFile from './LessonFile';
import axios from 'axios';

const GameLesson = ({ files, changeState, user, defi, points, unlocked, getUser, nbFails }) => {

  const [initd, setInitd] = useState(false);
  const [scores, setScores] = useState();
  const [bon, setBon] = useState(0);

  const endGame = () => {
    changeState();
  }

  const getFailsBonus = () => {
    if (nbFails === 0) {
      return 15;
    }
    else if (nbFails < 5) {
      return nbFails * 2;
    }
    else {
      return 10;
    }
  }

  const resetCheckpoint = () => {
    axios.post('https://connivence-salon.net/api/e-learning/game/post/checkpoint.php', new URLSearchParams({
      user: user.id, checkpoint: 0, points: 0
    })).then(response => {
      console.log("Progression sauvegardée");
    }).catch(error => {
      // Gérer l'erreur ici, par exemple en affichant un message d'erreur
      console.error('Erreur lors de l\'envoi de la requête:', error);
      // Peut-être définir un état pour afficher l'erreur dans le popup
    });
  }

  useEffect(() => {
    console.log(defi)
    if(document.querySelector("header").classList.contains("slides-header")){
      setTimeout(() => {
        document.querySelector("header").classList.remove("slides-header");
      }, 1000);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto' // Cette ligne est facultative car 'auto' est le comportement par défaut
    });
    document.body.classList.add("day-mode");
    document.body.classList.add("files-bg");
    return () => {
      document.body.classList.remove("day-mode");
      document.body.classList.remove("files-bg");
    }
  }, [])

  useEffect(() => {
    console.log(files);
    resetCheckpoint();

    axios.get('https://connivence-salon.net/api/e-learning/content/achievements.php', {
      params: {
        user: user?.id
      }
    })
      .then(response => {
        if (response.data && response.data.success) {
          setScores(response.data.content);
          axios.get('https://connivence-salon.net/api/e-learning/game/get/success.php', {
            params: {
              defi,
              user: user?.id
            }
          })
            .then(response => {
              if (response.data && response.data.rank) {
                const rank = parseInt(response.data.rank);
                let bn = 0;
                if (rank === 1) {
                  bn = 20;
                  setBon(20);

                }
                else if (rank === 2) {
                  bn = 16;
                  setBon(16);
                }
                else if (rank === 3) {
                  bn = 10;
                  setBon(10);
                }
                else {
                  bn = 0;
                  setBon(0);
                }

                if (initd === false) {
                  axios.post('https://connivence-salon.net/api/e-learning/game/post/endgame.php', new URLSearchParams({
                    user: user.id, defi, points: parseInt(points) + getFailsBonus() + bn
                  })).then(response => {
                    setInitd(true);
                  }).catch(error => {
                    // Gérer l'erreur ici, par exemple en affichant un message d'erreur
                    console.error('Erreur lors de l\'envoi de la requête:', error);
                    // Peut-être définir un état pour afficher l'erreur dans le popup
                  });
                }
              }
              // Traiter la réponse ici
            })
            .catch(error => {
              console.error('Erreur:', error);
              // Gérer l'erreur ici
            });
        }
        // Traiter la réponse ici
      })
      .catch(error => {
        console.error('Erreur:', error);
        // Gérer l'erreur ici
      });
  }, [user]);
  return (
    <section className="game-lesson" >
      <div className="lesson-intro">
        <Emoji src="files" />
        <p>
          Félicitations ! <strong>Vous avez terminé le défi de la semaine avec succès</strong> 🥳 {defi === 12 || defi === 13 ? "" : "Vous avez donc débloqué de nouvelles fiches de cours."}
        </p>
        <p>
          {defi === 12 || defi === 13 ? <strong className='red'>Dans les prochains défis, vous pourrez télécharger des fiches techniques sur cet écran !</strong> : "Ne partez-pas ! Vous pourrez récolter vos points et améliorer vos récompenses après avoir téléchargé ces fiches..."}
        </p>
        <ul>

            {
              files.map((file, index) =>
                <LessonFile lesson={file} key={index} />
              )
            }

        </ul>
      </div>



      <button onClick={() => endGame()}>Suivant</button>
    </section>
  );
};

export default GameLesson;