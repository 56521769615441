import React, { useState } from 'react';
import { useEffect } from 'react';

const Header = ({ parameter, title, state, lesson, minified, color }) => {

  const [logoPath, setLogoPath] = useState("../assets/images/logo_sans.png");

  const colorTitle = () => {
    let baseTitle = title[2].split("//");
    if(baseTitle.length > 0 && baseTitle[1]){
      console.log(baseTitle);
      baseTitle[1] = "<span class='inline red'>" + baseTitle[1]?.trim() + "</span>";
      return baseTitle[0] + baseTitle[1];
    }
    else{
      return title[2];
    }
  }


  useEffect(() => {
    if (document.body.classList.contains("day-mode") || (color && color == "black")) {
      setLogoPath("https://connivence-salon.net/api/e-learning/assets/images/S1E1/BLACK-LOGO_bleublancrouge.png");
    }
    else {
      if (parameter) {
        setLogoPath("../assets/images/logo_sans.png");
      }
      else {
        setLogoPath("./assets/images/logo_sans.png");
      }
    }
  }, [document.body.classList, title, parameter])

  return (
    <header>
      <img src="../assets/images/lesson-header.png" alt="Entête de cours" className='lesson-header' />
      {
        title ?
          <ul className='season-ep'>
            <li style={title[0] === "Bande annonce" ? {opacity: "0"} : {opacity: "1"}}>{title[0]}</li>
            <li style={title[0] === "Bande annonce" ? {opacity: "0"} : {opacity: "1"}}>{title[1]}</li>
          </ul>
          //<div dangerouslySetInnerHTML={state < 4 ? { __html: title } : { __html: "La semaine prochaine..." }}></h1>
          :
          ""
      }
      {
        !minified || minified === false ?
          <img src={parameter ? "../assets/images/logo.png" : "./assets/images/logo.png"} alt="Logo Connivence Academy" />
          :
          <img className={title ? "minified top" : "minified"} src={logoPath} alt="Logo Connivence Academy" />
      }

      {
        title ?
          <h1 dangerouslySetInnerHTML={state < 4 ? { __html: colorTitle() } : { __html: "La semaine prochaine..." }}></h1>
          :
          ""
      }

    </header>
  );
};

export default Header;