import React, { useEffect, useRef, useState } from 'react';

const RedScreen = () => {

  const [animating, setAnimating] = useState(false);

  const element = useRef(null);
  const timeouts = useRef([]);

  useEffect(() => {
    if (!animating) {
      setAnimating(true);

      timeouts.current.push(
        setTimeout(() => {
          if (element.current) element.current.style.opacity = "0.9";
        }, 100)
      );

      timeouts.current.push(
        setTimeout(() => {
          if (element.current) element.current.style.transition = "opacity .5s";
        }, 200)
      );

      timeouts.current.push(
        setTimeout(() => {
          if (element.current) element.current.style.opacity = "0";
        }, 300)
      );

      timeouts.current.push(
        setTimeout(() => {
          if (element.current) {
            element.current.style.transition = "opacity .1s";
            setAnimating(true);
          }
        }, 800)
      );
    }

    return () => {
      // Clear all timeouts when the component unmounts
      timeouts.current.forEach((timeout) => clearTimeout(timeout));
      timeouts.current = [];
    };
  }, []);


  return (
    <div className='red-screen' ref={element}>
      
    </div>
  );
};

export default RedScreen;